import { Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { Post } from "../components";
import { Article } from "../content";
import aboutusmarkdown from "../content/about_us.md";

const AboutUs: React.FC = (): JSX.Element => {
    const currentArticle: Article = {
        id: "1",
        slug: "about-us",
        author: "Khadijah Peek",
        authorImage: "/images/dijah.jpg",
        image: "https://images.unsplash.com/photo-1512626120412-faf41adb4874?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80",
        title: "This is our story",
        summary: "Etiam nisl sem, ultrices sit amet venenatis vel, hendrerit at purus. In hac habitasse platea dictumst. Cras posuere, turpis a rhoncus porta, urna orci rutrum mauris, a molestie leo dolor nec velit. Mauris nisl ex, condimentum in augue tristique, commodo maximus diam. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec fermentum fringilla felis et consequat. Cras imperdiet vehicula fringilla. Proin luctus nec sapien vel facilisis. Curabitur turpis dolor, lacinia at ipsum sed, tristique cursus lectus. Donec non semper lacus. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
        date: "September 3rd, 2021"
    };

    const [content, setContent] = useState({ md: "" });
    useEffect(() => {
        fetch(aboutusmarkdown)
            .then((res) => res.text())
            .then((md) => {
                setContent({ md })
            })
    }, [])

    return (
        <>
            <Container maxWidth="lg">
                <Post
                    author={currentArticle.author}
                    authorImage={currentArticle.authorImage}
                    content={content.md}
                    date={currentArticle.date}
                    id={currentArticle.id}
                    image={currentArticle.image}
                    slug={currentArticle.slug}
                    title={currentArticle.title}
                />
            </Container>
        </>
    );
}

export default AboutUs;