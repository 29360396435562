/*[object Object]*/
import React from 'react';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { AppBar, Container, Toolbar, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
    appBar: {
        backgroundColor: "primary"
    },
    articlesContainer: {
        paddingTop: theme.spacing(3),

    },
    articleTitle: {
        fontWeight: 800,
        paddingBottom: theme.spacing(3)
    },
    author: {
        display: "flex"
    },
    card: {
        maxWidth: "100%",
    },
    cardActions: {
        display: "flex",
        margin: "0 10px",
        justifyContent: "space-between",
    },
    featuredImage: {
        height: 240,
        width: "100%",
    },
    getInvolved: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        paddingTop: "75px",
        paddingBottom: "100px"
    },
    footer: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    },
    hero: {
        backgroundImage: `linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)), url('https://images.unsplash.com/photo-1576014131795-d440191a8e8b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')`,
        height: "200px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontSize: "4rem",
    },
    media: { height: 240, },
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    mainFeaturedPostNoImage: {
        position: 'relative',
        marginBottom: theme.spacing(4),

    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },
    personalMessage: {
        paddingTop: "30px"
    }
}));


type IShellOwnProps = {
    showHero?: boolean | true,
    children?: React.ReactNode
}

const Shell: React.FC<IShellOwnProps> = ({ children }: IShellOwnProps): JSX.Element => {
    const classes = useStyles();

    return (
        <>
            <AppBar className={classes.appBar} position="static">
                <Toolbar>
                    <Typography variant="h6">Tridentru Capital Region Cannabis Dispensary</Typography>
                </Toolbar>
            </AppBar>
            <main>
                {children}
            </main>
            <footer className={classes.footer} >
                <Container >
                    <Typography align="center" variant="h6" gutterBottom>
                        Tridentru
                    </Typography>

                    <Typography align="center" variant="subtitle1"  >
                        Contact us: info@tridentru.com
                    </Typography>
                    <Typography align="center" variant="subtitle2"  >
                        Copyright 2021
                    </Typography>
                </Container>
            </footer>
        </>
    )
}

export { Shell };