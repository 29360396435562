/* \\ Author : Scott Sargent */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Landing from "./pages/landing";
import AboutUs from "./pages/aboutus";
import CompanyObjective from './pages/companyObjective';
import { Shell } from "./components";
import Market from './pages/market';
import HistoryOfCannabis from './pages/historyOfCannabis';
import OurTeam from './pages/ourTeam';
import SocialEquity from './pages/socialEquity';

const App: React.FC = (): JSX.Element => {
  return (<>
    <Router>
      <Switch>
        <Route path="/" exact>
          <Landing />
        </Route>
        <Route path="/about-us">
          <Shell>
            <AboutUs />
          </Shell>
        </Route>
        <Route path="/company-objective">
          <Shell>
            <CompanyObjective />
          </Shell>
        </Route>
        <Route path="/market">
          <Shell>
            <Market />
          </Shell>
        </Route>
        <Route path="/social-equity">
          <Shell>
            <SocialEquity />
          </Shell>
        </Route>
        <Route path="/history-of-cannabis">
          <Shell>
            <HistoryOfCannabis />
          </Shell>
        </Route>
        <Route path="/our-team">
          <Shell>
            <OurTeam />
          </Shell>
        </Route>
      </Switch>
    </Router>
  </>)
}

export default App;
