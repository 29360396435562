import { Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { Post } from "../components";
import { Article } from "../content";
import contentResource from "../content/company_objective.md";

const CompanyObjective: React.FC = (): JSX.Element => {
    const currentArticle: Article = {
        id: "2",
        slug: "company-objective",
        author: "Khadijah Peek",
        authorImage: "/images/dijah.jpg",
        image: "https://images.unsplash.com/photo-1516321497487-e288fb19713f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80",
        title: "Company Objective",
        summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eget nisi vitae magna molestie tincidunt. Vivamus vel iaculis est. Duis quis vestibulum augue. Nunc fermentum luctus nulla, vitae finibus nunc.",
        date: "September 3rd, 2021"
    }
    const [content, setContent] = useState({ md: "" });
    useEffect(() => {
        fetch(contentResource)
            .then((res) => res.text())
            .then((md) => {
                setContent({ md })
            })
    }, [])

    return (
        <>
            <Container maxWidth="lg">
                <Post
                    author={currentArticle.author}
                    authorImage={currentArticle.authorImage}
                    content={content.md}
                    date={currentArticle.date}
                    id={currentArticle.id}
                    image={currentArticle.image}
                    slug={currentArticle.slug}
                    title={currentArticle.title}
                />
            </Container>
        </>
    );
}

export default CompanyObjective;