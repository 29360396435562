import { Avatar, Box, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import React from "react"
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";

type ByLineOwnProps = {
    author: string,
    authorImage: string,
    image: string,
    title: string,
    date: string,
}


const useStyles = makeStyles(() => createStyles({
    author: {
        display: "flex"
    },
    byline: {
        display: "flex",
        margin: "0 10px",
        justifyContent: "space-between",
    },
    postImageContainer: {

    },
    postImage: {
        width: "100%",
        maxWidth: "100%",
        objectFit: "cover",
    }
}));


//const Landing: React.FC = (): JSX.Element => {
const ByLine: React.FC<ByLineOwnProps> = ({ author, authorImage, image, title, date }: ByLineOwnProps): JSX.Element => {
    const classes = useStyles();

    return (
        <>
            <h1>{title}</h1>
            <Container className={classes.byline} maxWidth="lg" >
                <Grid spacing={3} container>
                    <Grid className={classes.byline} sm={12} md={12} lg={12} item>
                        <Box className={classes.author}>
                            <Avatar src={authorImage} />
                            <Box ml={2}>
                                <Typography component="p" variant="subtitle2">
                                    {author}
                                </Typography>
                                <Typography color="textSecondary" component="p" variant="subtitle2">
                                    {date}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <BookmarkBorderIcon />
                        </Box>
                    </Grid>
                    <Grid sm={12} item>
                        <Box className={classes.postImage}>
                            <img className={classes.postImage} src={image} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export { ByLine }