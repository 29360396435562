import { Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { Post } from "../components";
import { Article } from "../content";
import contentResource from "../content/social_equity.md";

const SocialEquity: React.FC = (): JSX.Element => {
    const currentArticle: Article = {
        id: "4",
        slug: "social-equity",
        author: "Khadijah Peek",
        authorImage: "/images/dijah.jpg",
        image: "https://images.unsplash.com/photo-1499244571948-7ccddb3583f1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2378&q=80",
        title: "Social and Economic Equity",
        summary: "Quisque tristique elit tempus, vulputate nunc eu, consequat turpis. Suspendisse congue egestas tortor, ut sollicitudin enim molestie in. Nulla facilisi. Duis consequat, justo quis accumsan maximus.",
        date: "September 3rd, 2021"
    };

    const [content, setContent] = useState({ md: "" });
    useEffect(() => {
        fetch(contentResource)
            .then((res) => res.text())
            .then((md) => {
                setContent({ md })
            })
    }, [])

    return (
        <>
            <Container maxWidth="lg">
                <Post
                    author={currentArticle.author}
                    authorImage={currentArticle.authorImage}
                    content={content.md}
                    date={currentArticle.date}
                    id={currentArticle.id}
                    image={currentArticle.image}
                    slug={currentArticle.slug}
                    title={currentArticle.title}
                />
            </Container>
        </>
    );
}

export default SocialEquity;