import { Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { Post } from "../components";
import { Article } from "../content";
import contentResource from "../content/our_team.md";

const OurTeam: React.FC = (): JSX.Element => {
    const currentArticle: Article = {
        id: "6",
        slug: "our-team",
        author: "Khadijah Peek",
        authorImage: "/images/dijah.jpg",
        image: "https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80",
        title: "Our Team",
        summary: "Maecenas vel purus eu ex sodales iaculis sit amet ac magna. Maecenas fringilla libero id enim tincidunt rutrum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi.",
        date: "September 3rd, 2021"
    }
    const [content, setContent] = useState({ md: "" });
    useEffect(() => {
        fetch(contentResource)
            .then((res) => res.text())
            .then((md) => {
                setContent({ md })
            })
    }, [])

    return (
        <>
            <Container maxWidth="lg">
                <Post
                    author={currentArticle.author}
                    authorImage={currentArticle.authorImage}
                    content={content.md}
                    date={currentArticle.date}
                    id={currentArticle.id}
                    image={currentArticle.image}
                    slug={currentArticle.slug}
                    title={currentArticle.title}
                />
            </Container>
        </>
    );
}

export default OurTeam;