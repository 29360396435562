/*[object Object]*/
export type Article = {
    id: string;
    slug: string;
    author: string;
    authorImage: string;
    title: string;
    image: string;
    date: string;
    summary: string;
};

const articles: Article[] = [
    {
        id: "2",
        slug: "company-objective",
        author: "Khadijah Peek",
        authorImage: "/images/dijah.jpg",
        image: "https://images.unsplash.com/photo-1516321497487-e288fb19713f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80",
        title: "Company Objective",
        summary: "Tridentru intends to obtain a New York State recreational cannabis license issued by the Office of Cannabis Management (OCM) in 2022. Tridentru will operate three dispensaries in the Upstate region of Saratoga, Schenectady, and Warren counties.",
        date: "September 3rd, 2021"
    },
    {
        id: "3",
        slug: "market",
        author: "Khadijah Peek",
        authorImage: "/images/dijah.jpg",
        image: "https://images.unsplash.com/photo-1526304640581-d334cdbbf45e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80",
        title: "Market",
        summary: "New York State has become the 15th regulated recreational marijuana market in the United States.",
        date: "September 3rd, 2021"
    },
    {
        id: "4",
        slug: "social-equity",
        author: "Khadijah Peek",
        authorImage: "/images/dijah.jpg",
        image: "https://images.unsplash.com/photo-1499244571948-7ccddb3583f1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2378&q=80",
        title: "Social and Economic Equity",
        summary: "Tridentru is proud of the role we are playing in creating a more socially forward and equitable industry; our success will help to create a framework for social reform programs of the future.",
        date: "September 3rd, 2021"
    },
    {
        id: "5",
        slug: "history-of-cannabis",
        author: "Khadijah Peek",
        authorImage: "/images/dijah.jpg",
        image: "https://images.unsplash.com/photo-1599227705778-6427976abdc7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2250&q=80",
        title: "History of Cannabis",
        summary: "Cannabis has been a part of our civilization since as far back as our history can take us. We are proud to play a role in its resurgence in the modern world as a tool for wellness, both for the individual and the community.",
        date: "September 3rd, 2021"
    },
    //https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80
    {
        id: "6",
        slug: "our-team",
        author: "Khadijah Peek",
        authorImage: "/images/dijah.jpg",
        image: "https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80",
        title: "Our Team",
        summary: "Tridentru has assembled an exemplary Team to ensure it success.",
        date: "September 3rd, 2021"
    },
]

const getArticles = (): Article[] => {
    return articles
}

export { getArticles }