import React from "react";
import { Box, Breadcrumbs, Grid, Link, Typography } from "@material-ui/core";
import ReactMarkdown from "react-markdown";

import { AlsoRead, ByLine } from ".";

type PostOwnProps = {
    author: string,
    authorImage: string,
    content: string,
    date: string,
    image: string
    id: string,
    slug: string
    title: string,
}

const Post: React.FC<PostOwnProps> = ({ author, authorImage, content, date, image, slug, title }: PostOwnProps): JSX.Element => {

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/">
                    Tridentru
                </Link>
                <Typography color="textPrimary">{title}</Typography>
            </Breadcrumbs>
            <Grid spacing={3} container>
                <Grid md={12} sm={12} xs={12} item>
                    <ByLine
                        author={author}
                        authorImage={authorImage}
                        date={date}
                        image={image}
                        title={title} />
                    <ReactMarkdown>
                        {content}
                    </ReactMarkdown>
                </Grid>
                <Grid md={12} sm={12} xs={12} item>

                    <Box>
                        <AlsoRead omit={slug} />
                    </Box>

                </Grid>
            </Grid>
        </>
    )
}

export { Post }