/*[object Object]*/
/* \\ Author : Scott Sargent */
import React, { useState, useEffect } from 'react';
import { AppBar, Avatar, Box, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Link, Paper, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";

import { Article, getArticles } from "../content";

const useStyles = makeStyles((theme) => createStyles({
    appBar: {
        backgroundColor: "secondary"
    },
    articlesContainer: {
        paddingTop: theme.spacing(3),

    },
    articleTitle: {
        fontWeight: 800,
        paddingBottom: theme.spacing(3)
    },
    author: {
        display: "flex"
    },
    card: {
        maxWidth: "100%",
    },
    cardContent: {
        height: "415px",
    },
    cardActions: {
        display: "flex",
        margin: "0 10px",
        justifyContent: "space-between",
    },
    featuredImage: {
        height: 240,
        width: "100%",
    },
    getInvolved: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        paddingTop: "75px",
        paddingBottom: "100px"
    },
    footer: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    },
    hero: {
        // backgroundImage: `linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)), url('https://images.unsplash.com/photo-1576014131795-d440191a8e8b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')`,
        height: "500px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontSize: "4rem",
    },
    media: { height: 240, },
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    mainFeaturedPostNoImage: {
        position: 'relative',
        marginBottom: theme.spacing(4),

    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },
    personalMessage: {
        paddingTop: "30px"
    },
    video: {
        width: "100%",
        height: "100%",
        position: "absolute",
        objectFit: "cover",
        zIndex: 0,
    },
    videoLogoBox: {
        zIndex: 1,
    }
}));

const Landing: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const [articles, setArticles] = useState<Article[]>([]);

    useEffect(() => {
        const arts = getArticles();
        setArticles(arts)
    }, []);

    const featured: Article = {
        id: "1",
        slug: "about-us",
        author: "Khadijah Peek",
        authorImage: "https://images.unsplash.com/photo-1561406636-b80293969660?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80",
        image: "https://images.unsplash.com/photo-1512626120412-faf41adb4874?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80",
        title: "This is our story",
        summary: "Tridentru is a Cannabis company built for the modern era. We strongly believe in the power of Cannabis to improve health, wellness, and to change the world for the better. It is the belief that we are putting something good, and needed, back into the world that keeps us coming back every morning and putting our full heart and soul into our work.",
        date: "September 3rd, 2021"
    };

    return (<>
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" >Tridentru Capital Region Cannabis Dispensary</Typography>
            </Toolbar>
        </AppBar>
        <Box className={classes.hero}>
            <video className={classes.video} autoPlay muted loop>
                <source src="/videos/tridentru-green-video-720.mp4" type="video/mp4" />
            </video>
            <Box className={classes.videoLogoBox}>
                <img alt="Tridentru" src="/images/logo-green.png" width="500" />
            </Box>
        </Box>
        <main>
            <Container className={classes.personalMessage} maxWidth="lg">
                <Paper className={classes.mainFeaturedPostNoImage}>
                    <Grid container>
                        <Grid md={12} item>
                            <div className={classes.mainFeaturedPostContent}>
                                <Typography color="inherit" component="h1" variant="h3" gutterBottom>
                                    {featured.title}
                                </Typography>
                                <Typography color="inherit" variant="h5" paragraph>
                                    {featured.summary}
                                </Typography>
                                <Link href="/about-us" variant="subtitle1">
                                    Read More
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <Container className={classes.articlesContainer} maxWidth="lg">
                <Typography className={classes.articleTitle} variant="h4">
                    Get to know us better
                </Typography>
                <Grid spacing={3} container>
                    {articles.map<JSX.Element>(a => (
                        <Grid key={a.id} md={4} sm={6} xs={12} item>
                            <Card className={classes.card}>
                                <CardActionArea className={classes.cardContent} href={`/${a.slug}`}>
                                    <CardMedia
                                        className={classes.media}
                                        image={a.image}
                                        title={a.title}
                                    />
                                    <CardContent>
                                        <Typography component="h2" variant="h5" gutterBottom>
                                            {a.title}
                                        </Typography>
                                        <Typography color="textSecondary" component="p" variant="body2">
                                            {a.summary}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions className={classes.cardActions}>
                                    <Box className={classes.author}>
                                        <Avatar src={a.authorImage} />
                                        <Box ml={2}>
                                            <Typography component="p" variant="subtitle2">
                                                {a.author}
                                            </Typography>
                                            <Typography color="textSecondary" component="p" variant="subtitle2">
                                                {a.date}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <BookmarkBorderIcon />
                                    </Box>
                                </CardActions>
                            </Card>
                        </Grid>))}
                </Grid>
            </Container>
            <Container className={classes.getInvolved}>
                <Box>
                    <Typography variant="h4">
                        Get Involved
                    </Typography>
                    <Typography component="p" variant="body2">
                        We are really excited to serve our community.  Please check back here often to learn ways that you might get involved.
                    </Typography>
                </Box>
            </Container>
        </main>
        <footer className={classes.footer} >
            <Container >
                <Typography align="center" variant="h6" gutterBottom>
                    Tridentru
                </Typography>
                <Typography align="center" variant="subtitle1"  >
                    info@tridentru.com
                </Typography>
                <Typography align="center" variant="subtitle2"  >
                    Copyright 2021
                </Typography>
            </Container>
        </footer>
    </>)
}

export default Landing;
