import { Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { Post } from "../components";
import { Article } from "../content";
import contentResource from "../content/history_of_cannabis.md";

const HistoryOfCannabis: React.FC = (): JSX.Element => {
    const currentArticle: Article = {
        id: "5",
        slug: "history-of-cannabis",
        author: "Khadijah Peek",
        authorImage: "/images/dijah.jpg",
        image: "https://images.unsplash.com/photo-1599227705778-6427976abdc7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2250&q=80",
        title: "History of Cannabis",
        summary: "tiam nisl sem, ultrices sit amet venenatis vel, hendrerit at purus. In hac habitasse platea dictumst. Cras posuere, turpis a rhoncus porta, urna orci rutrum mauris, a molestie leo dolor nec velit.",
        date: "September 3rd, 2021"
    }
    const [content, setContent] = useState({ md: "" });
    useEffect(() => {
        fetch(contentResource)
            .then((res) => res.text())
            .then((md) => {
                setContent({ md })
            })
    }, [])

    return (
        <>
            <Container maxWidth="lg">
                <Post
                    author={currentArticle.author}
                    authorImage={currentArticle.authorImage}
                    content={content.md}
                    date={currentArticle.date}
                    id={currentArticle.id}
                    image={currentArticle.image}
                    slug={currentArticle.slug}
                    title={currentArticle.title}
                />
            </Container>
        </>
    );
}

export default HistoryOfCannabis;