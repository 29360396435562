import { Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { Post } from "../components";
import { Article } from "../content";
import contentResource from "../content/market.md";

const Market: React.FC = (): JSX.Element => {
    const currentArticle: Article = {
        id: "3",
        slug: "market",
        author: "Khadijah Peek",
        authorImage: "/images/dijah.jpg",
        image: "https://images.unsplash.com/photo-1526304640581-d334cdbbf45e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80",
        title: "The Market",
        summary: "Curabitur turpis dolor, lacinia at ipsum sed, tristique cursus lectus. Donec non semper lacus. Interdum et malesuada fames ac ante ipsum primis in faucibus.   Nunc fermentum luctus nulla, vitae finibus nunc.",
        date: "September 3rd, 2021"
    }
    const [content, setContent] = useState({ md: "" });
    useEffect(() => {
        fetch(contentResource)
            .then((res) => res.text())
            .then((md) => {
                setContent({ md })
            })
    }, [])

    return (
        <>
            <Container maxWidth="lg">
                <Post
                    author={currentArticle.author}
                    authorImage={currentArticle.authorImage}
                    content={content.md}
                    date={currentArticle.date}
                    id={currentArticle.id}
                    image={currentArticle.image}
                    slug={currentArticle.slug}
                    title={currentArticle.title}
                />
            </Container>
        </>
    );
}

export default Market;