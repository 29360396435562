/*[object Object]*/
import { createTheme } from '@material-ui/core/styles';

const purpleTheme = createTheme({
  palette: {
    primary: {
      main: "#a07fa3",
    },
    secondary: {
      main: "#dfd3b4",
    },
  },
});

export default purpleTheme;