import { Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { Article, getArticles } from "../content";

type AlsoReadOwnProps = {
    omit: string,
}

const useStyles = makeStyles((theme) => createStyles({
    alsoRead: {
        marginTop: "150px",
        marginBottom: "100px",
    },
    appBar: {
        backgroundColor: "primary"
    },
    articlesContainer: {
        paddingTop: theme.spacing(3),

    },
    articleTitle: {
        fontWeight: 800,
        paddingBottom: theme.spacing(3)
    },
    author: {
        display: "flex"
    },
    card: {
        maxWidth: "100%",
    },
    cardContent: {
        height: "415px",
    },
    cardActions: {
        display: "flex",
        margin: "0 10px",
        justifyContent: "space-between",
    },
    featuredImage: {
        height: 240,
        width: "100%",
    },
    getInvolved: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        paddingTop: "75px",
        paddingBottom: "100px"
    },
    footer: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    },
    hero: {
        // backgroundImage: `linear-gradient(rgba(0,0,0, 0.5), rgba(0,0,0,0.5)), url('https://images.unsplash.com/photo-1576014131795-d440191a8e8b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')`,
        height: "500px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontSize: "4rem",
    },
    media: { height: 240, },
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    mainFeaturedPostNoImage: {
        position: 'relative',
        marginBottom: theme.spacing(4),

    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    mainFeaturedPostContent: {
        position: 'relative',
        padding: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            paddingRight: 0,
        },
    },
    personalMessage: {
        paddingTop: "30px"
    },
    video: {
        width: "100%",
        height: "100%",
        position: "absolute",
        objectFit: "cover",
        zIndex: 0,
    },
    videoLogoBox: {
        zIndex: 1,
    }
}));

const AlsoRead: React.FC<AlsoReadOwnProps> = ({ omit }: AlsoReadOwnProps): JSX.Element => {
    const [articles, setArticles] = useState<Article[]>([]);
    const classes = useStyles();
    useEffect(() => {
        const arts = getArticles();
        setArticles(arts)
    }, []);

    return (
        <Container className={classes.alsoRead}>
            <Typography variant="h4">
                You might also enjoy one of our other articles:
            </Typography>
            <Grid spacing={3} container>
                {articles.filter(a => a.slug !== omit).map<JSX.Element>(a => (
                    <Grid key={a.id} md={3} sm={4} xs={12} item>
                        <Card className={classes.card}>
                            <CardActionArea className={classes.cardContent} href={`/${a.slug}`}>
                                <CardMedia
                                    className={classes.media}
                                    image={a.image}
                                    title={a.title}
                                />
                                <CardContent>
                                    <Typography component="h2" variant="h5" gutterBottom>
                                        {a.title}
                                    </Typography>
                                    <Typography color="textSecondary" component="p" variant="body2">
                                        {a.summary}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>))}
            </Grid>
        </Container>
    )
}

export { AlsoRead };